.apps-apps-panel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  max-width: 47rem;
  max-height: calc(721px - 9rem);

  width: 50%;
  height: 45%;

  min-width: 47rem;
  min-height: 39.5rem;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 2rem 0 2rem;
  gap: 4.5rem;

  @media only screen and (max-height: 700px) {
    padding: 64px;
    padding-top: 0;
    gap: 16px;
  }

  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(62px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;

  .apps-apps-appbar {
    margin-left: -1.25rem;
  }

  .apps-apps-header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .apps-apps-content {
    padding: 0 2rem;
  }
}