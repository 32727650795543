$z-index: (
  modal : 200,
  navigation : 100,
  footer : 90,
  box: 60,
  background: 50,
  page: 10,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
$aqua50: #d9f3f3;
$aqua100: #96f6f1;
$aqua200: #00efea;
$aqua300: #00e5e5;
$aqua400: #00dce0;
$aqua500: #00d4de;
$aqua600: #00c3cb;
$aqua700: #00adb0;
$aqua800: #008486;
$aqua900: #066667;

$juice50: #fff3e0;
$juice100: #ffe0b2;
$juice200: #ffcd80;
$juice300: #ffb84d;
$juice400: #ffa826;
$juice500: #ff9700;
$juice600: #f88d00;
$juice700: #f67d00;
$juice800: #ef6d00;
$juice900: #e65200;

$lapis50: #efe5fd;
$lapis100: #cec5f4;
$lapis200: #ac9eee;
$lapis300: #8876e8;
$lapis400: #6959e3;
$lapis500: #443cdd;
$lapis600: #3538d7;
$lapis700: #1530ce;
$lapis800: #002bc7;
$lapis900: #001fbd;

$grape50: #f0e5fc;
$grape100: #d7c0f7;
$grape200: #bd95f2;
$grape300: #a167ee;
$grape400: #8b40ea;
$grape500: #7200e5;
$grape600: #6700d7;
$grape700: #5600d7;
$grape800: #4400d2;
$grape900: #1600cb;

$navy50: #e5e5eb;
$navy100: #b9bdcf;
$navy200: #8393ae;
$navy300: #626a8f;
$navy400: #454d7a;
$navy500: #273267;
$navy600: #212c5f;
$navy700: #1a2455;
$navy800: #131b49;
$navy900: #0a0b33;

$dark50: #fdfdfd;
$dark100: #f8f8f8;
$dark200: #f3f3f3;
$dark300: #eaeaea;
$dark400: #cfcfcf;
$dark500: #b2b2b2;
$dark600: #888888;
$dark700: #737373;
$dark800: #535353;
$dark900: #303030;

$bley100: #f4f6fb;
$bley400: #e7ebf5;
$bley500: #d9dde8;
$bley600: #9ea7bf;
$bley700: #697186;
$bley800: #4d556b;

$purewhite: #ffffff;
$white: #fafafc;
//syncfusion theme colours
$theme-primary: $aqua900;
$theme-dark-alt: $aqua900;
$theme-dark: $aqua900;
$theme-darker: $aqua900;

$theme-lighter: $aqua400;
$theme-lighter-alt: $aqua600;

$theme-secondary: $aqua600;
$theme-tertiary: $aqua400;

//ecs additional theme colours
$theme-ecs-background: $bley400;
$theme-ecs-background400: $bley400;
$theme-ecs-background200: $bley100;
$theme-ecs-background-lighten: lighten($theme-ecs-background, 10%);
$theme-ecs-background-darken: darken($theme-ecs-background, 10%);
$theme-ecs-background-light: $purewhite;
$theme-ecs-background-dark: $navy900;
$theme-ecs-background-alt-dark: $bley700;
$theme-ecs-background-alt: $grape900;
$theme-ecs-background-alt-light: $bley100;

$theme-ecs-default: $theme-ecs-background;
$theme-ecs-primary: $theme-primary;
$theme-ecs-secondary: $grape400;
$theme-ecs-tertiary: $juice800;

$theme-ecs-default-dark: $theme-ecs-background-darken;
$theme-ecs-primary-dark: $theme-dark;
$theme-ecs-secondary-dark: $grape500;
$theme-ecs-tertiary-dark: $juice900;

$theme-ecs-clr-light: $purewhite;
$theme-ecs-clr-dark: $navy900;
$theme-ecs-clr-alt: $dark900;
$theme-ecs-clr-transparent-light: $navy100;
$theme-ecs-clr-transparent-dark: $navy200;
$theme-ecs-clr-transparent-alt: $grape500;

$theme-ecs-dimension-xsmall: 0.875rem;
$theme-ecs-dimension-small: 1.625rem;
$theme-ecs-dimension-medium: 2.5rem;
$theme-ecs-dimension-large: 3.0rem;
$theme-ecs-dimension-xlarge: 3.875rem;
$theme-ecs-dimension-xxlarge: 4.625rem;

$theme-ecs-rounddimension-xsmall: 1rem;
$theme-ecs-rounddimension-small: 1.725rem;
$theme-ecs-rounddimension-medium: 2.5rem;
$theme-ecs-rounddimension-large: 3.0rem;
$theme-ecs-rounddimension-xlarge: 4.05rem;
$theme-ecs-rounddimension-xxlarge: 4.825rem;

$font-size: 0.875rem;

/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-base/styles/material.scss';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-react-grids/styles/fabric.css';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-buttons/styles/fabric.css';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-dropdowns/styles/material.scss';/*** --- ***/  
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-popups/styles/material.scss';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-inputs/styles/material.scss';/*** --- ***/  
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-calendars/styles/material.scss';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-icons/styles/material.scss';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-react-calendars/styles/material.scss';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import "~@syncfusion/ej2-react-dropdowns/styles/material.scss";/*** --- ***/

// Custom component styling
.ecs-box {
  &.ecs-banner-notice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 auto;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    transition: box-shadow 0.3s ease-in-out;
    position: absolute;
    z-index: 1000;
    bottom: 50px;
    width: 80vw;
    min-width: 500px;
    left: 50%;
    transform: translateX(-50%);

    .ecs-banner-notice-dismiss:hover {
      cursor: pointer;
    }

    .ecs-banner-notice-text {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 1px;
      font-size: 1rem;
    }

    .ecs-banner-notice-icon {
      color: $aqua900;
      flex-grow: 1;
      max-width: 24px;
    }

    &.ecs-banner-notice-info {
      background-color: $aqua50;

      .ecs-banner-notice-icon {
        color: $aqua900;
      }

      .ecs-banner-notice-text {
        color: $aqua900;
      }
    }

    &.ecs-banner-notice-warning {
      background-color: $juice50;

      .ecs-banner-notice-icon {
        color: $juice900;
      }
      .ecs-banner-notice-text {
        color: $juice900;
      }
    }
  }
}

.ecs-box {
  &.ecs-banner-notice-relative {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 auto;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    transition: box-shadow 0.3s ease-in-out;

    .ecs-banner-notice-dismiss:hover {
      cursor: pointer;
    }

    .ecs-banner-notice-text {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 1px;
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 500;
    }

    .ecs-banner-notice-icon {
      color: $aqua900;
      flex-grow: 1;
      max-width: 24px;
    }

    &.ecs-banner-notice-info {
      background-color: $aqua50;

      .ecs-banner-notice-icon {
        color: $aqua900;
      }

      .ecs-banner-notice-text {
        color: $aqua900;
      }
    }

    &.ecs-banner-notice-warning {
      background-color: $juice50;

      .ecs-banner-notice-icon {
        color: $juice900;
      }
      .ecs-banner-notice-text {
        color: $juice900;
      }
    }
  }
}

.ecs-banner {
  fill: $purewhite;
}
.ecs-box {
    font-family: 'Avenir Medium';
    display: flex;
    flex-direction: column; //default
    flex: 1 1 auto;
    overflow: hidden;
    @include z-index(box);

    &.ecs-box-row {
        flex-direction: row;
    }

    &.ecs-rounded-round {
        align-items: start;
        justify-content: center;
    }

    &.ecs-box-blur {
        $bluropacity: 0.85;

        &.ecs-background-default {
            background: rgba($theme-ecs-background, $bluropacity);
        }

        &.ecs-background-light {
            background: rgba($theme-ecs-background-light, $bluropacity);
        }

        &.ecs-background-dark {
            background: rgba($theme-ecs-background-dark, $bluropacity);
        }

        &.ecs-background-alt {
            background: rgba($theme-ecs-background-alt, $bluropacity);
        }

        &.ecs-background-alt-light {
            background: rgba($theme-ecs-background-alt-light, $bluropacity);
        }

        backdrop-filter: blur(4rem);
    }

    .ecs-box-shadow {
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
    }
}

.ecs-box > .dashboard-widget > .logi-embed {
    position: relative !important;
}
.ecs-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @include z-index(page);

  & .ecs-page-background-image {
    @include z-index(background);
    background: none;
    position: absolute;
    top: 10%;
    right: -50%;
    height: 120vmax;
    opacity: 0.7;
  }
}

.ecs-sidemenu {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 25rem;
  @include z-index(box);

  .ecs-sidemenu-actions {
    overflow: unset;
  }

  &.ecs-sidemenu-collapsed {
    flex: 0 0 auto;

    &.ecs-sidemenu-collapsed-xxlarge {
      width: $theme-ecs-rounddimension-xxlarge;
    }

    &.ecs-sidemenu-collapsed-xlarge {
      width: $theme-ecs-rounddimension-xlarge;
    }

    &.ecs-sidemenu-collapsed-large {
      width: $theme-ecs-rounddimension-large;
    }

    &.ecs-sidemenu-collapsed-medium {
      width: $theme-ecs-rounddimension-medium;
    }

    &.ecs-sidemenu-collapsed-small {
      width: $theme-ecs-rounddimension-small;
    }
  }

  .ecs-sidemenu-divider {
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
      display: flex;
      height: 0;
      border: 0.0675rem solid $dark400;
      opacity: 0.5;
      margin: 0;
    }

    &.ecs-sidemenu-divider-full {
      padding: 0 0.5rem;
      opacity: 0.5;

      &>div {
        flex-grow: 1;
      }
    }

    &.ecs-sidemenu-divider-xlarge {
      width: $theme-ecs-dimension-xlarge;

      &>div {
        width: $theme-ecs-dimension-small;
      }
    }

    &.ecs-sidemenu-divider-large {
      width: $theme-ecs-dimension-large;

      &>div {
        width: $theme-ecs-dimension-xsmall;
      }
    }

    &.ecs-sidemenu-divider-medium {
      width: $theme-ecs-dimension-medium;

      &>div {
        width: $theme-ecs-dimension-xsmall;
      }
    }

    &.ecs-sidemenu-divider-small {
      width: $theme-ecs-dimension-small;

      &>div {
        width: $theme-ecs-dimension-xsmall;
      }
    }
  }
}
.e-grid-container {
  min-width: 30rem;
  min-height: 30rem;
}

.e-grid {
  font-family: 'Avenir Book';
  font-weight: 'normal';

  border-style: none;
  background: none;

  width: 100%;
  max-width: 100%;
  min-width: unset;
  max-height: calc(100% - 96px);

  .e-grid-column-template {
    word-wrap: break-word;
    white-space: break-spaces;
    cursor: pointer;

    .e-grid-comment-summary {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      cursor: pointer;

      &:hover {
        color: $theme-primary !important;
        text-decoration: underline;
      }
    }
  }

  .e-gridheader {
    background-color: transparent !important;
    font-family: 'Avenir Medium' !important;
    border-width: 0.0625rem;
    border-radius: 1rem 1rem 0 0;
    border: 1px solid $bley500;

    tr.e-columnheader {
      height: 3rem;
    }

    & * {
      background-color: transparent !important;
    }

    .e-headertext {
      font-family: 'Avenir Heavy' !important;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.03125rem;
      color: $bley700;
    }

    .e-headercell {
      padding-left: 1rem !important;

      .e-headercelldiv {
        padding-right: 0.5rem;
      }

      .e-rhandler {
        border-right: 2px solid transparent;

        &:hover {
          border-right: 2px solid $dark300;
        }
      }
    }

    th.e-headercell.e-lastcell .e-rhandler {
      border-right-width: 2px !important;
    }
  }

  .e-dialog.e-filter-popup {
    position: absolute;
    top: 2.813rem !important;
    background: $purewhite;
    box-shadow: 0px 0px 74px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: 1px solid $purewhite;
    transform: translateX(7%);

    &:after {
      position: absolute;
      left: 216px;
      top: -14px;
      width: 0;
      height: 0;
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 20px solid $purewhite;
    }

    .e-footer-content {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      display: flex;
      padding: 1.25rem 1rem 1.25rem;

      .e-btn.e-flmenu-okbtn {
        box-sizing: border-box;
        padding: 0px 1rem;
        width: 4.188rem;
        height: 2.5rem;
        background: $aqua900;
        border: 1px solid $aqua900;
        border-radius: 0.5rem;
        margin-left: 0;
        font-family: 'Avenir Medium';
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 19px;
        color: $purewhite;
      }

      .e-btn.e-flmenu-cancelbtn {
        height: 2.5rem;
        font-family: 'Avenir Medium';
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 19px;
        color: $aqua900;
        background-color: transparent;
        outline: none;
        border: none;
        margin-left: 0;
      }
    }

    .e-dlg-content {
      border-radius: 8px !important;
      padding: 23px 16px 10px;


      .e-flmenu-maindiv {
        margin-top: 12px;
      }

      .e-flmenu-valuediv {
        padding: 16px 0 0;
      }

      .e-popup-flmenu.e-control-wrapper {
        border-radius: 8px;
        padding: 4px;
        color: $bley700;
        font-family: 'Avenir Medium';
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

        .e-input-group-icon {
          color: $aqua900;
        }
      }
    }
  }

  .e-gridcontent {
    font-family: 'Avenir Book' !important;
    background-color: white !important;
    border-width: 0.0625rem;
    border-radius: 0 0 1rem 1rem;
    border: 1px solid $bley500;
    height: calc(100% - 9.125rem) !important;

    // could target this with more precision
    & a {
      font-family: 'Avenir Medium' !important;
    }

    .e-row {
      height: 7rem;
    }

    .e-rowcell {
      padding: 1rem;
      vertical-align: top;
      color: $bley700 !important;
      font-size: 1rem;
      font-weight: 400;
      min-height: 8rem;
      height: 8rem;
      max-height: 8rem;
    }

    td.e-rowcell.e-active {
      background-color: $bley100;
    }

    .e-rowcell-wrap {
      word-wrap: break-word !important;
      white-space: break-spaces !important;
      overflow: hidden;
    }

    .e-rowcell.e-templatecell.editable-column {
      height: 3.5rem !important;
      padding: 0px;
      .e-gridform {
        padding: 0 1.063rem;
        padding-top: 0.125rem;
      }
      .editable-cell  {
        margin: 1px;
        padding: 1rem;
        min-height: 1.25rem;
        &:hover {
          color: $aqua800;
          cursor: pointer;
          margin: 0px;
          border: 1px solid $aqua800;
        }
      }
    }
  }

  &.e-gridhover {
    tr.e-row:hover
      .e-rowcell:not(.e-cellselectionbackground):not(.e-updatedtd):not(
        .e-indentcell
      ),
    tr.e-frozenhover {
      background-color: $bley100 !important;
    }
  }

  & .e-frozenheader > .e-table,
  & .e-frozencontent > .e-table,
  & .e-frozencontent .e-virtualtable > .e-table,
  & .e-frozenheader .e-virtualtable > .e-table {
    border-right-color: $bley500; // customize the frozen table’s border color
  }

  .e-gridpager {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.e-ccdlg.e-popup-open.e-dialog {
  position: absolute;
  top: 0 !important;
  left: auto !important;
  right: 20px;
  background: $purewhite;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 1px solid $purewhite;

  &:after {
    position: absolute;
    left: 216px;
    top: -14px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid $purewhite;
  }

  .e-footer-content {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    padding: 20px 16px 25px;
    justify-content: space-between;

    .e-btn.e-flat.e-primary,
    .e-css.e-btn.e-flat.e-primary {
      box-sizing: border-box;
      padding: 0px 16px;
      width: 85px;
      height: 40px;
      background: $aqua900;
      border: 1px solid $aqua900;
      backdrop-filter: blur(5px);
      border-radius: 8px;
      margin-left: 0;
      font-family: 'Avenir Medium';
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      color: $purewhite;
    }

    .e-btn.e-flat.e-primary {
      text-indent: -9999px;
      line-height: 0;
    }

    .e-btn.e-flat.e-primary:after {
      content: 'Apply';
      text-indent: 0;
      display: block;
      line-height: initial;
    }

    .e-btn.e-flat,
    .e-css.e-btn.e-flat {
      height: 40px;
      font-family: 'Avenir Medium';
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      color: $aqua900;
      background-color: transparent;
      outline: none;
      border: none;
      margin-left: 0;
    }
  }

  .e-dlg-content {
    border-radius: 8px !important;
    padding: 23px 16px 10px;

    .e-input-group:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: $bley700;
      border-radius: 5px;
      padding: 5px;
    }

    .e-grid .e-ccdlg .e-cc-searchdiv span.e-ccsearch-icon.e-icons {
      color: $aqua900;
    }

    .e-main-div {
      .e-cc-contentdiv {
        height: auto !important;
      }
    }

    .e-ccul-ele .e-cc:first-child {
      display: none;
    }

    .e-checkbox-wrapper .e-frame.e-stop,
    .e-css.e-checkbox-wrapper .e-frame.e-stop,
    .e-checkbox-wrapper .e-frame,
    .e-css.e-checkbox-wrapper .e-frame {
      border-radius: 5px;
      border-color: $navy100;
      background-color: $navy100;
      color: $purewhite;
    }
    .e-checkbox-wrapper .e-frame,
    .e-css.e-checkbox-wrapper .e-frame {
      background-color: $purewhite;
    }
    .e-checkbox-wrapper .e-frame.e-check,
    .e-css.e-checkbox-wrapper .e-frame.e-check {
      border-radius: 5px;
      border-color: $aqua900;
      background-color: $aqua900;
      color: $purewhite;
    }
    .e-checkbox-wrapper .e-label {
      color: $navy300;
    }

    .e-cc-searchdiv {
      left: 0 !important;

      .e-input-group-icon {
        color: $aqua900;
        font-size: 17px;
      }
    }
  }
}

.item-details-properties-grid, .item-custom-type-grid {
  .e-row,
  .e-rowcell {
    height: 55px !important;

    .e-treecolumn-container {
      gap: 0.25rem;
      .e-treecell {
        max-width: calc(100% - 0.625rem);
        padding-right: 0.5rem;
      }
    }
  }

  .e-grid {
    max-height: calc(100% - 0.938rem);
    .e-content {
      max-height: calc(100vh - 27.188rem) !important;
    }

    .e-editedrow .e-input-group.e-control-wrapper input.e-input {
        font-family: 'Avenir Book';
        font-size: 1rem;
        color: $bley700;
    }

    .e-editedbatchcell {
        padding-top: 0.688rem !important;
        padding-bottom: 0.75rem !important;
    }

    .e-gridform {
        min-height: 1.813rem;
        height: auto;
    }

    .e-gridcontent .e-row,
    .e-rowcell {
        height: 3.375rem !important;
        max-height: 3.375rem !important;
        overflow: hidden;
    }

    .e-rowcell.e-templatecell.editable-column {
      height: auto !important;
      min-height: 3.375rem;
      
      .e-gridform {
        padding: 0 1rem;
      }
      .noneditable-cell {
        padding: 1rem;
      }
      .editable-cell  {
        height: auto !important;
        min-height: 1.25rem;
        margin: 1px;
        padding: 1rem;
        &:hover {
          .editable-text {
            color: $aqua800 !important;
          }
          cursor: pointer;
          margin: 0px;
          border: 1px solid $aqua800;
        }
      }
    }
  }

  .e-row .e-rowcell:first-child {
    border-right: 2px solid $bley500;
    padding-right: 0;
  }

  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, 
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, 
    input.e-input::selection {
    background: $aqua800 !important;
  }

  .e-input-group.e-control-wrapper .e-input-group-icon,
  .e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active
  .e-input-group.e-control-wrapper .e-input-group-icon:hover,
  .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
    color: $aqua800 !important;
  }

  .e-input-group .e-input[disabled] {
    color: $navy900 !important;
  }

  .e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border-color: $navy900 !important;
    min-height: 1.688rem;
    height: auto;
  }

  .e-treecolumn-container {
    gap: 0.25rem;
    span {
      color: $navy900 !important;
    }
  }

  .e-spinner-pane.e-spin-show {
    width: 50%;
  }

  .e-spinner-pane .e-spinner-inner .e-spin-material {
    stroke: #008486;
  }
}

#snapshots-grid {
  .e-row,
  .e-rowcell {
    height: 24px !important;
    align-content: center;
  }

  .e-row .e-rowcell:not(:last-child) {
    padding: 1rem;
  }

  .e-row .e-rowcell:last-child {
    padding: 0.5rem;
  }

  .e-grid {
    max-height: 100%;
    .e-content {
      max-height: 100vh !important;
    }
  }

  .e-row .e-rowcell:first-child {
    border-right: 2px solid $bley500;
  }
}

.e-grid .e-icon-filter::before {
  content: '\e7ee' !important;
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
  content: '\e36a' !important;
}

.e-input-group-icon.e-date-icon::before,
.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e322' !important;
}

.e-grid  {
  .e-filtered::before {
    color: $aqua800;
  }

  .e-dialog.e-filter-popup .e-dlg-content { 
    padding: .25rem 1rem 0.625rem;

    &::after {
      content: " ";
      width: 100%;
      height: 0.063rem;
      background-color: $bley500;
      display: block;
      margin: 1rem 0 0.375rem;
    }
    
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
      background: transparent;
    }

    .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
      transition: none;
    }
    
    .e-popup-flmenu.e-control-wrapper {
      display: flex;
      align-items: center;
      border-width: 0.063rem !important;
      padding: 0 1rem;
      border-radius: 0.5rem;
      color: $bley700;
      border-color: $navy100 !important;
      height: 2.5rem;
      font-family: 'Avenir Book';
      font-size: 0.875rem;
      margin-bottom: 0;

      input.e-input::selection {
        background: $aqua800;
      }

      .e-input-group-icon.e-date-icon {
        background-color: transparent !important;
        height: auto;
        &::before {
            font-family: 'FluentSystemIcons-Regular';
            content: '\e250' !important;
        }
      }
    }
    
    .e-control-wrapper {
      &:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
        border-color: $aqua600 !important;
      }
    
      &.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
          border-color: $aqua800 !important; 
      }
    }
  }
}

.e-calendar .e-content { 
  td.e-selected span.e-day { 
    background-color: $aqua800;
  }

  td.e-today span.e-day {
    color: $aqua800 !important;
    border: 0.063rem solid $aqua800 !important;
  }

  td.e-selected.e-focused-date span.e-day { 
    background-color: $aqua800 !important;
    color: $purewhite !important;
  }
}

.e-dropdownbase {
  .e-list-item {
    font-family: 'Avenir Book';
    font-size: 0.875rem;
    color: $navy900;
    height: 2.5rem;
  }
  
  .e-list-item.e-active, .e-list-item.e-hover {
    background-color: $bley100;
  }

  .e-list-item.e-active.e-hover {
    color: $navy900;
  }

  .e-list-item.e-active {
    color: $aqua800 !important;
  }
}

.items-tree-grid-with-filter {
  .e-grid .e-gridcontent {
    max-height: calc(100% - 3.125rem);
    height: 100% !important;
  }
}

.item-panel-items-grid {
  .e-gridcontent {
    height: calc(100% - 3.75rem) !important;
  }
}

.item-panel-members-grid {
  .e-gridcontent {
    height: calc(100% - 3.75rem) !important;
  }
}

.ecs-application {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0);
  border: 1px solid transparent;

  transition: box-shadow 0.2s, border 0.2s, background-color 0.2s;
  background-color: $theme-ecs-background-alt-light;

  &:hover {
    cursor: pointer;
    background-color: $theme-ecs-background-light;

    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid $bley400;
  }

  .ecs-application-sub-pane {
    background-color: transparent !important;
  }
}

.dr-details td {
  padding: 20px 0;
}

.dr-details tr td:first-child {
  font-weight: bold;
  color: $navy900;
  width: 13%;
}

.dr-details tr td,
.dr-details-item {
  font-size: 14px;
  color: $bley700;
}

.dr-details-item span {
  text-align: left;
}
.ecs-application-panel {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  border-radius: 0.75rem !important;
  justify-content: flex-start;
  row-gap: 1.5rem;

  transition: box-shadow 0.2s, border 0.2s, background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: $theme-ecs-background-light;

    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid $bley400;
  }

  .ecs-application-panel-logo {
    margin-top: 1rem;
    height: 4.5rem;
    width: 4.5rem;
  }

  .ecs-application-panel-name {
    margin-bottom: 1.375rem;
  }
}

.ecs-application-panel-small {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  border-radius: 0.75rem !important;

  transition: box-shadow 0.2s, border 0.2s, background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: $theme-ecs-background-light;

    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid $bley400;
  }

  .ecs-application-panel-logo {
    margin-top: 1rem;
  }

  .ecs-application-panel-name {
    margin-bottom: 0.75rem;
    font-size: 0.75rem;
  }
}
$z-index: (
  modal : 200,
  navigation : 100,
  footer : 90,
  box: 60,
  background: 50,
  page: 10,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

.community-page-background-image {
    @include z-index('background');
    background: none;
    position: absolute;
    right: 0;
    bottom: 0;
    order: 0;
    flex-grow: 0;
    height: 80vh;
    opacity: 0.3;
  }
  
.datepicker {
    .e-date-wrapper.e-control-wrapper,
    .e-datetime-wrapper.e-control-wrapper {
        display: flex;
        align-items: center;
        border-width: 0.063rem !important;
        padding: 0 0.5rem;
        border-radius: 0.5rem;
        color: $bley800;
        border-color: $bley800 !important;
        height: 3rem;

        &::after,
        &::before {
            background: transparent !important;
        }

        & .e-input-group-icon::before {
            color: #008486
        }

        .e-input-group-icon.e-date-icon {
            background-color: transparent !important;
            height: auto;
            &::before {
                font-family: 'FluentSystemIcons-Regular';
                content: '\e250' !important;
            }
        }

        .e-input-group-icon.e-time-icon {
            margin: 0 0 4px 2px;
            font-size: 1.063rem;
            height: auto;
            background-color: transparent !important;
            &::before {
                font-family: 'FluentSystemIcons-Regular';
                content: '\f2df' !important;
            }
        }

        & input.e-input::selection {
            background: inherit;
            color: inherit;
        }

        &:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
            border-color: $aqua600 !important;
        }

        &.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
            border-color: #008486 !important; 
        }
    }

    .e-date-wrapper.e-control-wrapper {
        width: 94% !important;
    }
}

.custom-datepicker{
    .e-calendar .e-content td.e-selected span.e-day { 
        background-color: $aqua800;
    }

    .e-calendar .e-content td.e-today span.e-day {
        color: $aqua800 !important;
        border: 1px solid $aqua800 !important;
    }

    .e-calendar .e-content td.e-selected.e-focused-date span.e-day { 
        background-color: $aqua800;
        color: $purewhite !important;
    }
}

.custom-datepicker.e-datetimepicker {
    .e-list-parent.e-ul { 
        .e-list-item {
            font-family: 'Avenir Book';
            font-size: 0.875rem;
            color: $navy900;
            height: 2.5rem;
        }
        
        .e-list-item.e-active, .e-list-item.e-hover {
            background-color: $bley100;
        }
        
        .e-list-item.e-active.e-hover {
            color: $navy900;
        }

        .e-list-item.e-active {
            color: $aqua800 !important;
        }
    }
}


.chip-link:hover {
    border-color: $aqua600;
    background-color: $bley100;
}

.chip-link:active {
    border-color: $bley700;
    background-color: $bley600;
}

//ecs styles used for components
//contains global component classes

.ecs-background-none {
  background-color: transparent;
  color: $theme-ecs-clr-dark;
}

.ecs-background-default {
  background-color: $theme-ecs-background;
  color: $theme-ecs-clr-dark;
}

.ecs-background-light {
  background-color: $theme-ecs-background-light;
  color: $theme-ecs-clr-dark;
}

.ecs-background-dark {
  background-color: $theme-ecs-background-dark;
  color: $theme-ecs-clr-light;
}

.ecs-background-alt {
  background-color: $theme-ecs-background-alt;
  color: $theme-ecs-clr-light;
}

.ecs-background-alt-light {
  background-color: $theme-ecs-background-alt-light;
  color: $theme-ecs-clr-dark;
}

.ecs-background-primary {
  background-color: $theme-ecs-primary;
  color: $theme-ecs-clr-light;
}

.ecs-background-secondary {
  background-color: $theme-ecs-secondary;
  color: $theme-ecs-clr-light;
}

.ecs-background-tertiary {
  background-color: $theme-ecs-tertiary;
  color: $theme-ecs-clr-light;
}

.ecs-fill-default {
  fill: $theme-ecs-background;
}

.ecs-fill-light {
  fill: $theme-ecs-background-light;
}

.ecs-fill-dark {
  fill: $theme-ecs-background-dark;
}

.ecs-fill-alt-dark {
  fill: $theme-ecs-background-alt-dark;
}

.ecs-fill-alt {
  fill: $theme-ecs-background-alt;
}

.ecs-fill-alt-light {
  fill: $theme-ecs-background-alt-light;
}

.ecs-rounded-none {
  border-radius: 0;
}

.ecs-rounded-all {
  border-radius: 1rem;
}

.ecs-rounded-top {
  border-radius: 1rem 1rem 0 0;
}

.ecs-rounded-bottom {
  border-radius: 0 0 1rem 1rem;
}

.ecs-rounded-round {
  border-radius: 3vmin;
}

.ecs-padding-none {
  padding: 0;
}

.ecs-padding-xsmall {
  padding: 0.25rem;
}

.ecs-padding-small {
  padding: 0.5rem;
}

.ecs-padding-medium {
  padding: 1rem;
}

.ecs-padding-large {
  padding: 1.5rem;
}

.ecs-padding-xlarge {
  padding: 2rem;
}

.ecs-gap-none {
  gap: 0;
}

.ecs-gap-xsmall {
  gap: 0.25rem;
}

.ecs-gap-small {
  gap: 0.5rem;
}

.ecs-gap-medium {
  gap: 1rem;
}

.ecs-gap-large {
  gap: 1.5rem;
}

.ecs-gap-xlarge {
  gap: 2rem;
}

.ecs-border-none {
  border-width: 0;
}

.ecs-border-xsmall {
  border-width: 0.0625rem;
}

.ecs-border-small {
  border-width: 0.125rem;
}

.ecs-border-medium {
  border-width: 0.1875rem;
}

.ecs-border-large {
  border-width: 0.25rem;
}

.ecs-border-xlarge {
  border-width: 0.375rem;
}

.ecs-border-xxlarge {
  border-width: 0.5rem;
}

.ecs-border-color-none {
  border-color: transparent;
}

.ecs-border-color-default {
  border-color: $theme-ecs-background;
}

.ecs-border-color-light {
  border-color: $theme-ecs-background-light;
}

.ecs-border-color-dark {
  border-color: $theme-ecs-background-dark;
}

.ecs-border-color-alt {
  border-color: $theme-ecs-background-alt;
}

.ecs-border-color-alt-light {
  border-color: $theme-ecs-background-alt-light;
}

.ecs-border-color-primary {
  border-color: $theme-ecs-primary;
}

.ecs-border-color-secondary {
  border-color: $theme-ecs-secondary;
}

.ecs-border-color-tertiary {
  border-color: $theme-ecs-tertiary;
}

.ecs-height-xxlarge {
  height: $theme-ecs-dimension-xxlarge;
}

.ecs-height-xlarge {
  height: $theme-ecs-dimension-xlarge;
}

.ecs-height-medium {
  height: $theme-ecs-dimension-xlarge;
}

.ecs-height-large {
  height: $theme-ecs-dimension-large;
}

.ecs-height-small {
  height: $theme-ecs-dimension-small;
}

.ecs-width-xxlarge {
  width: $theme-ecs-dimension-xxlarge;
}

.ecs-width-xlarge {
  width: $theme-ecs-dimension-xlarge;
}

.ecs-width-medium {
  width: $theme-ecs-dimension-xlarge;
}

.ecs-width-large {
  width: $theme-ecs-dimension-large;
}

.ecs-width-small {
  width: $theme-ecs-dimension-small;
}

//experimental
.ecs-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//treegrid customization

.e-treegrid .e-icons.e-none::before {
  content: '';
}

.e-treegrid .e-icons.e-none {
  width: 0;
  height: 0;
}

.e-treegrid .e-gridcontent .e-rowcell {
  height: unset !important;
}

.e-treegrid .e-grid .e-gridcontent .e-row {
  height: unset !important;
}

.e-treecolumn-container {
  display: flex;
  flex-direction: row;
}

.e-treecell {
  align-self: flex-start;
  margin-right: auto;
}

.e-grid .e-icons:not(.e-btn-icon) {
  color: #000;
}

.e-treegrid .e-treecolumn-container span {
  display: inline-block;
  vertical-align: middle;
}

.e-treegrid .e-default tr td:first-child {
  padding-right: 2rem;
}

.e-treegrid .e-treegridexpand,
.e-treegrid .e-treegridcollapse {
  color: #212121;
  cursor: pointer;
  font-size: 14px;
  height: 16px;
  padding: 0;
  text-align: center;
  vertical-align: bottom;
  min-width: 20px !important;
}

.items-grid-tree-grid .e-treegridcollapse {
  //This is to remove the expand capabilities
  display: none !important;
}

.e-treegrid .e-treegridexpand {
  transform: rotate(90deg);
}

.e-treegrid .e-treegridexpand::before,
.e-treegrid .e-treegridcollapse::before {
  text-align: center;
  vertical-align: middle;
  padding-left: '1.5rem';
}

.e-treegrid .e-treegridexpand::before,
.e-treegrid .e-treegridcollapse::before {
  content: '\e608';
}

.e-treegrid .e-none {
  width: 0px !important;
}

.e-treegrid .e-grid .e-rowcell {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.e-treegrid .e-rowcell {
  height: unset !important;
  max-height: unset !important;
}

@function exponential($base, $exponent) {
  $result: 1;

  @for $i from 1 through $exponent {
    $result: $result * $base;
  }

  @return $result;
}

@for $i from 1 through 100 {
  $padding: calc(60px * $i);

  [class*='level#{$i}'] .e-treecolumn-container {
    padding-left: $padding;
  }
}

@for $i from 1 through 100 {
  [class*='level#{$i}']
    .e-treecolumn-container
    > :nth-child(2):not(.e-treegridexpand):not(.e-treegridcollapse) {
    width: 1.3rem !important;
    flex-shrink: 0;
  }
}

@for $i from 1 through 100 {
  [class*='level#{$i}']
    .e-treecolumn-container
    > :nth-child(2):not(.e-treegridexpand):not(.e-treegridcollapse) {
    width: 1.3rem !important;
    flex-shrink: 0;
  }
}

.e-treegrid .e-treecell {
  width: 100%;
}

[class*='level0'] .e-treecolumn-container {
  padding-left: 0rem;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
  display: none;
}

.e-checkbox-wrapper,
.e-css.e-checkbox-wrapper {
  order: -1;
  margin-right: 1rem;
}

.e-treegrid .e-checkselect {
  opacity: 100 !important;
  width: auto !important;
}

.e-treegrid .e-checkselectall {
  opacity: 100 !important;
  width: auto !important;
}

//Item Grid Column Selector CSS

.e-treegrid .e-cc-contentdiv {
  border-bottom: 1px solid #dce1ef;
  border-top: 1px solid #dce1ef;
}

.e-treegrid .e-dlg-header-content {
  // height: 0rem;
  opacity: 0;
  padding: 1rem;
}

.e-treegrid .e-cc-searchdiv {
  top: -56px !important;
}

.e-treegrid .e-cc-chbox {
  display: unset !important;
}

.e-treegrid .e-checkbox-wrapper span {
  display: inline-block !important;
}

.e-treegrid .e-gridchkbox .e-checkbox-wrapper span {
  opacity: 0 !important;
}

.e-treegrid .e-headerchkcelldiv .e-checkbox-wrapper span {
  opacity: 0 !important;
}

.e-treegrid .e-headerchkcelldiv .e-checkbox-wrapper input {
  margin-top: 0.1rem;
}

.e-treegrid .e-footer-content {
  padding: 0rem 1rem 1rem !important;
  border-top: 1px;
  border-color: #000;
}

.e-treegrid .e-ccdlg .e-dlg-content {
  margin: 1rem 0 0.5rem;
}

.e-treegrid .e-ccdlg {
  right: 8px !important;
}

//Disposal Grid Column Selector CSS

.e-grid .e-cc-contentdiv {
  border-bottom: 1px solid #dce1ef;
  border-top: 1px solid #dce1ef;
}

.e-grid .e-dlg-header-content {
  height: 0rem;
  opacity: 0;
  padding: 1rem;
}

.e-grid .e-cc-searchdiv {
  top: -56px !important;
}

.e-grid .e-cc-chbox {
  display: unset !important;
}

.e-grid .e-checkbox-wrapper span {
  display: inline-block !important;
}

.e-grid .e-gridchkbox .e-checkbox-wrapper span {
  opacity: 0 !important;
}

.e-grid .e-headerchkcelldiv .e-checkbox-wrapper span {
  opacity: 0 !important;
}

.e-grid .e-headerchkcelldiv .e-checkbox-wrapper input {
  margin-top: 0.1rem;
}

.e-grid .e-footer-content {
  padding: 0rem 1rem 1rem !important;
  border-top: 1px;
  border-color: #000;
}

.e-grid .e-ccdlg .e-dlg-content {
  margin: 1rem 0 0.5rem;
}

//Items Grid Height Fix

.properties-tab-filter-dropdown {
  min-width: unset !important;
}

div.e-ddl.e-popup.e-popup-flmenu.e-lib.e-control.e-popup-open {
  &:has(.e-nodata) {
    display: none;
  }
}

.e-grid .e-ccdlg.e-popup-open.e-dialog .e-dlg-content .e-main-div .e-cc-contentdiv {
  height: 7rem !important;
}
.e-treegrid .e-ccdlg.e-popup-open.e-dialog .e-dlg-content .e-main-div .e-cc-contentdiv {
  height: 7rem !important;
}


// adding the styling here to overwrite the styling from the component.scss
// Tree Grid
.item-custom-type-tree-grid {
    .e-grid {
        .e-editedrow .e-input-group.e-control-wrapper input.e-input {
            font-family: 'Avenir Book';
            font-size: 1rem;
            color: $bley700;
        }

        .e-editedbatchcell {
            padding-top: 0.688rem !important;
            padding-bottom: 0.75rem !important;
        }

        .e-gridform {
            height: 1.813rem;
        }

        .e-gridcontent .e-row,
        .e-rowcell {
            height: 3.375rem !important;
            max-height: 3.375rem !important;
            overflow: hidden;
        }

        
    }
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, 
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, 
    input.e-input::selection {
        background: $aqua800 !important;
    }

    .e-input-group.e-control-wrapper .e-input-group-icon,
    .e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active
    .e-input-group.e-control-wrapper .e-input-group-icon:hover,
    .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
        color: $aqua800 !important;
    }

    .e-input-group .e-input[disabled] {
        color: $navy900 !important;
    }

    .e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
        border-color: $navy900 !important;
        height: 1.688rem;
    }

    .e-treecolumn-container {
        gap: 0.25rem;
        span {
            color: $navy900 !important;
        }
    }

    // TODO: update this code 100 should be dynamic, this will support up to 100 nested tree grid items
    @for $i from 1 through 100 {
        $padding: calc(36px * $i);
    
        [class*='level#{$i}'] .e-treecolumn-container {
        padding-left: $padding;
        }
    }

    .e-grid {
        max-height: none;
    }

    .e-gridcontent {
        max-height: 33rem;
        height: calc(100vh - 360px) !important;
    }
}

// Dropdown
.custom-type-dropdown {
    .e-dropdownbase {
        .e-list-item {
            font-family: 'Avenir Book';
            font-size: 0.875rem;
            color: $navy900;
            height: 2.5rem;
        }
        
        .e-list-item.e-active, .e-list-item.e-hover {
            background-color: $bley100;
        }

        .e-list-item.e-active.e-hover {
            color: $navy900;
        }
    }
}

.custom-type-dropdown.e-ddl.e-control-wrapper .e-ddl-icon::before, 
.custom-type-dropdown.e-ddl.e-control-wrapper.e-icon-anim .e-ddl-icon::before {
    transition: none;
}

// DatePicker
.custom-type-datepicker {
    .e-calendar .e-content td.e-selected span.e-day { 
        background-color: $aqua800;
    }

    .e-calendar .e-content td.e-today span.e-day {
        color: $aqua800 !important;
        border: 1px solid $aqua800 !important;
    }

    .e-calendar .e-content td.e-selected.e-focused-date span.e-day { 
        background-color: $aqua800;
        color: $purewhite !important;
    }
}

.custom-type-datepicker.e-control-wrapper .e-input-group-icon.e-date-icon::before {
    font-family: "FluentSystemIcons-Regular";
    content: "\e262" !important;
    color: $aqua800;
    font-size: 1.125rem;
}

.custom-type-numeric.e-numeric .e-input-group-icon.e-spin-up:before {
    font-family: "FluentSystemIcons-Filled";
    content: "\f2b6";
    color: $aqua800;
    font-size: 1.125rem;
}

.custom-type-numeric.e-numeric .e-input-group-icon.e-spin-down:before {
    font-family: "FluentSystemIcons-Filled";
    content: "\f2a3";
    color: $aqua800;
    font-size: 1.125rem;
}

.custom-type-datepicker.e-datetimepicker {
    .e-list-parent.e-ul { 
        .e-list-item {
            font-family: 'Avenir Book';
            font-size: 0.875rem;
            color: $navy900;
            height: 2.5rem;
        }
        
        .e-list-item.e-active, .e-list-item.e-hover {
            background-color: $bley100;
        }
        
        .e-list-item.e-active.e-hover {
            color: $navy900;
        }

        .e-list-item.e-active {
            color: $aqua800 !important;
        }
    }
}

@font-face {
  font-family: "Avenir Roman";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Roman"),
    url("../assets/fonts/Avenir/Avenir_Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Book";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Book"),
    url("../assets/fonts/Avenir/Avenir_Book.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Medium"),
    url("../assets/fonts/Avenir/Avenir_Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Heavy"),
    url("../assets/fonts/Avenir/Avenir_Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Black";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Black"),
    url("../assets/fonts/Avenir/Avenir_Black.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Book";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Book"),
    url("../assets/fonts/Avenir/Avenir_Book.ttf") format("truetype");
}

@font-face {
  font-family: "FluentSystemIcons-Filled";
  src: url("../assets/fonts/FluentUi/FluentSystemIcons-Filled.ttf") format("truetype");
}

@font-face {
  font-family: "FluentSystemIcons-Regular";
  src: url("../assets/fonts/FluentUi/FluentSystemIcons-Regular.ttf") format("truetype");
}



// Set global fonts
#root {
  font-family: "Avenir Book";
  font-weight: normal;
}

.e-control {
  color: $dark800;
  font-family: "Avenir Medium";
  font-weight: normal;
}

.e-tab .e-toolbar-items .e-toolbar-item .e-tab-text {
  font-family: "Avenir Book";
  font-weight: normal;
}

.e-card {
  font-family: "Avenir Book";
  font-weight: normal;
}