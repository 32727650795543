@import './components/Panel.scss';

.App {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.apps-page-container {
  background-color: rgba($bley400, 0.6);
  padding: 0 1rem 1rem 1rem;

  //are following class being used?
  .apps-page-background-image {
    background-color: $bley400;
  }

  .apps-page-container-inner {
    overflow: hidden;
    position: relative;
    background-color: $bley100;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);

    .apps-page-background-image {
      background-color: transparent;
      transform: translate(2rem, -4.35rem);
      opacity: 1;
    }
  }

  //---------
}

.apps-page-background-image {
  z-index: 50;
  background: none;
  position: absolute;
  right: 0;
  bottom: 0;
  order: 0;
  flex-grow: 0;
  height: 80vh;
  opacity: 0.3;
}
